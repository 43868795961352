<template>
  <div class="home-result">
    <strong>自主创新数字化转型赋能经典成果</strong>
    <p>跨行业跨领域为客户提供全生命周期自主创新数字化转型服务</p>
    <div>
      <div v-show="resultTagIndex == 0">
        <video-player
          :options="videoOptions1"
          :poster="require('@/assets/img/wzfm.jpg')"
        />
      </div>
      <div v-show="resultTagIndex == 1">
        <video-player
          :options="videoOptions2"
          :poster="require('@/assets/img/szfm.jpg')"
        />
      </div>
      <div v-show="resultTagIndex == 2">
        <video-player
          :options="videoOptions3"
          :poster="require('@/assets/img/ycfm.jpg')"
        />
      </div>
      <div v-show="resultTagIndex == 3">
        <video-player
          :options="videoOptions4"
          :poster="require('@/assets/img/zhfm.jpg')"
        />
      </div>
      <div class="home-result-info-box">
        <div class="home-result-info" :class="{ active: isInfo }">
          <strong>{{ resultInfo.title }}</strong>
          <p>{{ resultInfo.desc }}</p>
        </div>
        <div class="home-result-tag">
          <span
            v-for="(item, index) in resultList"
            :key="index"
            :class="{ active: resultTagIndex == index }"
            @mouseenter="resultMouseenter(item, index)"
            @mouseleave="isInfo = false"
            >{{ item.address }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./components/VideoPlayer.vue";
export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      videoOptions1: {
        autoplay: true,
        loop: true,
        muted: true,
        controls: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/main-site/wuzhen.mp4",
            type: "video/mp4",
          },
        ],
      },
      videoOptions2: {
        autoplay: true,
        loop: true,
        muted: true,
        controls: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/main-site/suzhou.mp4",
            type: "video/mp4",
          },
        ],
      },
      videoOptions3: {
        autoplay: true,
        loop: true,
        muted: true,
        controls: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/main-site/yancheng.mp4",
            type: "video/mp4",
          },
        ],
      },
      videoOptions4: {
        autoplay: true,
        loop: true,
        muted: true,
        controls: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/main-site/zhengzhou.mp4",
            type: "video/mp4",
          },
        ],
      },
      isInfo: false,
      resultInfo: {
        title: "中软国际（乌镇）鸿联联创中心",
        desc: "依托中软国际(乌镇)鸿联联创能力中心建设，以及乌镇“全球互联网创新展示沟通窗口”战略核心地位，协助桐乡培育和招引一批鸿蒙生态软硬件企业，推动规模化的国产AIOT企业汇聚和人才聚集;拉动一批传统硬件制造业企业的智能化改造;适配一批鸿蒙生态创新产品;沉淀一批智能场景解决方案;创新解决方案通过鸿联线上平台面向全球推广，使人民日常生活更加便利化，使企业产品更加智能化，优化本地产业结构，提升政府数字治理能力。推动桐乡全面融入鸿联物联网体系，成为鸿联生态发展高地。",
      },
      resultList: [
        {
          address: "浙江·乌镇",
          title: "中软国际（乌镇）鸿联联创中心",
          desc: "依托中软国际(乌镇)鸿联联创能力中心建设，以及乌镇“全球互联网创新展示沟通窗口”战略核心地位，协助桐乡培育和招引一批鸿蒙生态软硬件企业，推动规模化的国产AIOT企业汇聚和人才聚集;拉动一批传统硬件制造业企业的智能化改造;适配一批鸿蒙生态创新产品;沉淀一批智能场景解决方案;创新解决方案通过鸿联线上平台面向全球推广，使人民日常生活更加便利化，使企业产品更加智能化，优化本地产业结构，提升政府数字治理能力。推动桐乡全面融入鸿联物联网体系，成为鸿联生态发展高地。",
        },
        {
          address: "江苏·苏州",
          title: "中软国际（苏州）鸿联车联网联创中心",
          desc: "苏州高铁新城位于长三角中心位置，作为苏州“一核四城”发展战略的重要组成部分，也是相城区“一核三副”城市发展框架及五大功能片区发展规划的核心区域，规划面积28.9 平方公里。按照苏州市及相城区两级人民政府的建设规划要求，苏州高铁新城以建设国际化、现代化产业新城为目标，突出全球化、高端化发展导向，重点发展以先导产业为核心的科技研发、科技金融、文化创意产业，全力打造高质量发展新高地。",
        },
        {
          address: "江苏·盐城",
          title: "盐城Harmony智联科技创新园",
          desc: "2021年9月，盐城市人民政府与中软国际签署战略合作框架协议，双方合作共建鸿蒙科技创新园，并在盐城落地解放号鸿联联创中心，以场景构建带动技术应用，以数智服务汇聚产业生态，打造“两平台”+“三中心”+“N个示范场景”，共同构建鸿蒙生态产业、培养鸿蒙生态人才，将盐城市建设成为鸿蒙生态技术领先区、应用场景示范区、产业发展集聚区。",
        },
        {
          address: "河南·郑州",
          title: "中软国际郑州“中原数字总部”基地",
          desc: "郑州鸿联联创营围绕“赋能企业成长，联创解决方案；繁荣鸿蒙生态，加速产业集聚”，对上连接政府侧、集团总部，推动场景释放；对下赋能物联网、智能家居等产业，联创解决方案。",
        },
      ],
      resultTagIndex: 0,
    };
  },
  mounted() {},
  methods: {
    resultMouseenter(item, index) {
      this.resultInfo = item;
      this.resultTagIndex = index;
      this.isInfo = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-result {
  padding: 64px 0;
  background: url(../../assets/img/cgbg.png) no-repeat;
  background-size: 100% 100%;
  > strong {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: block;
    line-height: 45px;
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 28px;
  }
  > div {
    width: 1264px;
    height: 456px;
    margin: 48px auto 0;
    position: relative;
    > video {
      width: 1264px;
      height: 456px;
      display: block;
    }
    .home-result-info-box {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      .home-result-info {
        background: rgba(0, 0, 0, 0.25);
        padding: 0;
        width: 0;
        overflow: hidden;
        &.active {
          padding: 60px 40px;
          width: 533px;
        }
        > strong {
          display: block;
          font-size: 24px;
          color: #ffffff;
          line-height: 33px;
          margin-bottom: 20px;
        }
        > p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 24px;
          margin-bottom: 24px;
          text-align: justify;
        }
        > button {
          padding: 0 36px;
        }
      }
      .home-result-tag {
        width: 260px;
        height: 100%;
        > span {
          background: rgba(0, 0, 0, 0.45);
          border: 1px solid rgba(255, 255, 255, 0.15);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25%;
          font-size: 24px;
          color: #ffffff;
          cursor: pointer;
          transition: all 0.3s;
          &.active {
            color: #ea0b06;
            background: rgba(255, 255, 255, 0.65);
          }
        }
      }
      &:hover{
        .home-result-info{
          padding: 60px 40px;
          width: 533px;
        }
      }
    }
  }
}
</style>
