<template>
  <div class="home-platform">
    <strong>自主创新数字化转型赋能云平台</strong>
    <p>自主创新数字化转型搬运工</p>
    <div>
      <img alt="yptimg" src="@/assets/img/yptimg.png" />
      <div>
        <div class="home-platform-tag">
          <span
            :class="{ active: platformTagIndex == 1 }"
            @click="platformTagIndex = 1"
            >解决方案</span
          >
          <span
            :class="{ active: platformTagIndex == 2 }"
            @click="platformTagIndex = 2"
            >loT产品</span
          >
          <span
            :class="{ active: platformTagIndex == 3 }"
            @click="platformTagIndex = 3"
            >开发板</span
          >
          <span
            :class="{ active: platformTagIndex == 4 }"
            @click="platformTagIndex = 4"
            >SOC</span
          >
          <span
            :class="{ active: platformTagIndex == 5 }"
            @click="platformTagIndex = 5"
            >OS</span
          >
        </div>
        <div class="home-platform-list" v-if="platformTagIndex == 1">
          <div
            :key="index"
            @click="solutionBtn('solution', item.lastId, item.id)"
            v-for="(item, index) in solution"
          >
            <img :src="item.img" alt="yule" /><span>{{ item.name }}</span>
          </div>
        </div>


        <div class="home-platform-list" v-else-if="platformTagIndex == 2">
          <div
            :key="index"
            @click="solutionBtn('iot', item.lastId, item.id)"
            v-for="(item, index) in iotData"
          >
            <img :src="item.img" alt="yule" /><span>{{ item.name }}</span>
          </div>
        </div>


        <div class="home-platform-list" v-else-if="platformTagIndex == 3">
          <div
            :key="index"
            @click="productInfo(item.url)"
            v-for="(item, index) in devData"
          >
            <img :src="item.img" alt="yule" /><span>{{ item.name }}</span>
          </div>
        </div>


        <div class="home-platform-list" v-else-if="platformTagIndex == 4">
          <div
            :key="index"
            @click="productInfo(item.url)"
            v-for="(item, index) in socData"
          >
            <img :src="item.img" alt="yule" /><span>{{ item.name }}</span>
          </div>
        </div>



        <div class="home-platform-list" v-else-if="platformTagIndex == 5">
          <div
            :key="index"
            @click="productInfo(item.url)"
            v-for="(item, index) in osData"
          >
            <img :src="item.img" alt="yule" /><span>{{ item.name }}</span>
          </div>
        </div>
        <p @click="productAll" v-if="platformTagIndex < 5">
          <span>查看更多 ></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platformTagIndex: 1,
      solution: [
        {
          lastId: "1500000000000000001",
          id: "1500000000000000011",
          name: "智慧办公",
          img: require("@/assets/img/zhbg.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000012",
          name: "影音娱乐",
          img: require("@/assets/img/yyyl.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000013",
          name: "旅游出行",
          img: require("@/assets/img/lycx.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000014",
          name: "运动健康",
          img: require("@/assets/img/ydjk.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000015",
          name: "工业环境保护",
          img: require("@/assets/img/gyhb.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000021",
          name: "金融",
          img: require("@/assets/img/jr1.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000022",
          name: "党政",
          img: require("@/assets/img/dz.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000023",
          name: "能源",
          img: require("@/assets/img/ny1.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000024",
          name: "教育",
          img: require("@/assets/img/jy12.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000025",
          name: "公检法",
          img: require("@/assets/img/gjf.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000026",
          name: "交通",
          img: require("@/assets/img/jt123.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000027",
          name: "医疗",
          img: require("@/assets/img/yl123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000031",
          name: "智慧供应链",
          img: require("@/assets/img/gyl123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000032",
          name: "智能制造",
          img: require("@/assets/img/znzz123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000033",
          name: "智能质检",
          img: require("@/assets/img/znzj12.png"),
        },
      ],
      iotData: [
        {
          lastId: "1500000000000000001",
          id: "1500000000000000011",
          name: "智慧办公",
          img: require("@/assets/img/iot-zhbg.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000012",
          name: "影音娱乐",
          img: require("@/assets/img/iot-yyyl.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000013",
          name: "旅游出行",
          img: require("@/assets/img/iot-lycx.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000014",
          name: "运动健康",
          img: require("@/assets/img/iot-ydjk.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000015",
          name: "工业环境保护",
          img: require("@/assets/img/iot-gyhb.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000021",
          name: "金融",
          img: require("@/assets/img/iot-jr1.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000022",
          name: "党政",
          img: require("@/assets/img/iot-dz.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000023",
          name: "能源",
          img: require("@/assets/img/iot-ny1.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000024",
          name: "教育",
          img: require("@/assets/img/iot-jy12.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000025",
          name: "公检法",
          img: require("@/assets/img/iot-gjf.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000026",
          name: "交通",
          img: require("@/assets/img/iot-jt123.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000027",
          name: "医疗",
          img: require("@/assets/img/iot-yl123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000031",
          name: "智慧供应链",
          img: require("@/assets/img/iot-gyl123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000032",
          name: "智能制造",
          img: require("@/assets/img/iot-znzz123.png"),
        },
        {
          lastId: "1500000000000000003",
          id: "1500000000000000033",
          name: "智能质检",
          img: require("@/assets/img/iot-znzj12.png"),
        },
      ],
      devData: [
        {
          name: "Shenkaihong LYEVK-3861A",
          url: `/replace/productInfo/dev?id=1517038009399156738`,
          img: require("@/assets/img/LYEVK-3861A.png"),
        },
        {
          name: "Shenkaihong LYEVK-3861B",
          url: `/replace/productInfo/dev?id=1517039175033987074`,
          img: require("@/assets/img/LYEVK-3861B.png"),
        },
        {
          name: "KHDVK-3566B",
          url: "/replace/productInfo/dev?id=1518634156190404609",
          img: require("@/assets/img/KHDVK-3566B.png"),
        },
        {
          name: "KHDVK-3588",
          url: "/replace/productInfo/dev?id=1518633081173512194",
          img: require("@/assets/img/RK3588.png"),
        },
        {
          name: "ASR5822朗国IoT WiFi开发板",
          url: "/replace/productInfo/dev?id=1518521657252188162",
          img: require("@/assets/img/ASR5822.png"),
        },
        {
          name: "HI3861传智教育元气派 GenkiPI",
          url: "/replace/productInfo/dev?id=1517080207536783361",
          img: require("@/assets/img/HI3861.png"),
        },
        {
          name: "Hi3861拓维Niobe",
          url: "/replace/productInfo/dev?id=1517083055750217729",
          img: require("@/assets/img/HI3861Niobe.png"),
        },
        {
          name: "XR50A基于XR872芯片开发模块",
          url: "/replace/productInfo/dev?id=1517081047165140994",
          img: require("@/assets/img/XR50A.png"),
        },
        {
          name: "博流BL-HWC-G1",
          url: "/replace/productInfo/dev?id=1517071473536237569",
          img: require("@/assets/img/BL-HWC-G1.png"),
        },
        {
          name: "博通BK7231M",
          url: "/replace/productInfo/dev?id=1517077557181251586",
          img: require("@/assets/img/BK7231M.png"),
        },
        {
          name: "博通BL2028N",
          url: "/replace/productInfo/dev?id=1517073918970658817",
          img: require("@/assets/img/BL2028N.png"),
        },
        {
          name: "宸松教育&润和Neptune",
          url: "/replace/productInfo/dev?id=1518474502315511809",
          img: require("@/assets/img/Neptune.png"),
        },
        {
          name: "黑胡桃Waffle Nano",
          url: "/replace/productInfo/dev?id=1517072534951002114",
          img: require("@/assets/img/WaffleNano.png"),
        },
        {
          name: "联盛德W800&润和Neptune",
          url: "/replace/productInfo/dev?id=1517079283321896961",
          img: require("@/assets/img/W800.png"),
        },
        {
          name: "全志XR806XR806",
          url: "/replace/productInfo/dev?id=1517082314130161665",
          img: require("@/assets/img/XR806XR806.png"),
        },
      ],
      socData: [
        {
          name: "LYB-S1B2 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545309462745698306",
          img: require("@/assets/img/LYB-S1B2.png"),
        },
        {
          name: "LYB-S3B2 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545310793397030914",
          img: require("@/assets/img/LYB-S3B2.png"),
        },
        {
          name: "智能电动车模块 KHS-B1ET-A",
          url: "/replace/productInfo/soc?id=1545315562765275138",
          img: require("@/assets/img/KHS-B1ET-A.png"),
        },
        {
          name: "LYB-P1B8 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545316592148140034",
          img: require("@/assets/img/LYB-P1B8.png"),
        },
        {
          name: "LYD-S1A1 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545318816374968322",
          img: require("@/assets/img/LYD-S1A1.png"),
        },
        {
          name: "C-8138 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545317604921561089",
          img: require("@/assets/img/C-8138.png"),
        },
        {
          name: "LYW-S1H1 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545317512462323713",
          img: require("@/assets/img/8910DM.png"),
        },
        {
          name: "小圆计数器 LYZ-2103",
          url: "/replace/productInfo/soc?id=1546663183899545602",
          img: require("@/assets/img/BES2600WM.png"),
        },
        {
          name: "小方计数器 LYZ-2102",
          url: "/replace/productInfo/soc?id=1546664376008822785",
          img: require("@/assets/img/BK7231M.png"),
        },
        {
          name: "智慧屏方案板 LYZ-2107",
          url: "/replace/productInfo/soc?id=1546665371514294273",
          img: require("@/assets/img/X2000.png"),
        },
        {
          name: "红外检测模块 KHS-SS1IPC-A",
          url: "/replace/productInfo/soc?id=1545310924053794818",
          img: require("@/assets/img/CST85F01.png"),
        },
        {
          name: "LYB-P1B7 智能物联网模组",
          url: "/replace/productInfo/soc?id=1545311938337165313",
          img: require("@/assets/img/ESP32C3.png"),
        },
        {
          name: "能量运动检测模块 LYS-B2SH-ZY0608",
          url: "/replace/productInfo/soc?id=1545312018293182465",
          img: require("@/assets/img/H3861V100.png"),
        },
        {
          name: "空气净化器模块 LYZ-2101",
          url: "/replace/productInfo/soc?id=1545314534208688129",
          img: require("@/assets/img/RK2206.png"),
        },
        {
          name: "智能包模块 CSC21010",
          url: "/replace/productInfo/soc?id=1545316471859695618",
          img: require("@/assets/img/RK3566.png"),
        },
      ],
      osData: [
        {
          name: "OpenHarmony V3.0 LTS",
          url: "/replace/productInfo/os?id=1517029690550812674",
          img: require("@/assets/img/OpenHarmony3_0.png"),
        },
        {
          name: "OpenHarmony V1.0 LTS",
          url: "/replace/productInfo/os?id=1517056817530720258",
          img: require("@/assets/img/OpenHarmony1_0.png"),
        },
        {
          name: "OpenHarmony V1.1.0-LTS",
          url: "/replace/productInfo/os?id=1517030174397333505",
          img: require("@/assets/img/OpenHarmony1_1_0.png"),
        },
        {
          name: "OpenHarmony V1.1.1-LTS",
          url: "/replace/productInfo/os?id=1517057323544137730",
          img: require("@/assets/img/OpenHarmony1_1_1.png"),
        },
        {
          name: "OpenHarmony V1.1.2-LTS",
          url: "/replace/productInfo/os?id=1517058524755054594",
          img: require("@/assets/img/OpenHarmony1_1_2.png"),
        },
        {
          name: "OpenHarmony V1.1.3 LTS",
          url: "/replace/productInfo/os?id=1517033587210223617",
          img: require("@/assets/img/OpenHarmony1_1_3.png"),
        },
        {
          name: "OpenHarmony V1.1.4 LTS",
          url: "/replace/productInfo/os?id=1517060575719378946",
          img: require("@/assets/img/OpenHarmony1_1_4.png"),
        },
        {
          name: "OpenHarmony V2.0 Canary",
          url: "/replace/productInfo/os?id=1517059445450919937",
          img: require("@/assets/img/OpenHarmony2_0.png"),
        },
        {
          name: "OpenHarmony V2.2 Beta2",
          url: "/replace/productInfo/os?id=1517061448201719810",
          img: require("@/assets/img/OpenHarmony2_2.png"),
        },
        {
          name: "OpenHarmony V3.0.1 LTS",
          url: "/replace/productInfo/os?id=1517062488443637762",
          img: require("@/assets/img/OpenHarmony3_0_1.png"),
        },
        {
          name: "OpenHarmony V3.1 Beta",
          url: "/replace/productInfo/os?id=1517062819856568322",
          img: require("@/assets/img/OpenHarmony3_1Beta.png"),
        },
        {
          name: "OpenHarmony V3.0.2 LTS",
          url: "/replace/productInfo/os?id=1517063428047425538",
          img: require("@/assets/img/OpenHarmony3_0_2.png"),
        },
        {
          name: "OpenHarmony V3.1 Release",
          url: "/replace/productInfo/os?id=1517063840968265729",
          img: require("@/assets/img/OpenHarmony3_1Release.png"),
        },
      ],
    };
  },
  methods: {
    solutionBtn(type, lastId, id) {
      if (type == "solution") {


        //this.$router.push(`/solution?id=${id}`)

        let text=this.$router.resolve(
          `/solution?id=${id}`
        )

        window.open(text.href,'_blank')


      } else if(type == "iot"){
         /* this.$router.push({
            name:'replace',
            params:{
              iotid:id,
              item:'3'
            }
          })*/
        let idI=`${id}3`
        let text=this.$router.resolve(
          `/replace?idI=${idI}`
        )


        window.open(text.href,'_blank')


      }

      else {

        //this.$router.push(`/replace?id=${id}`)
        let text=this.$router.resolve(
          `/replace?id=${id}`
        )

        window.open(text.href,'_blank')
      }
    },
    productInfo(url) {
      //this.$router.push(url)
      let text=this.$router.resolve(url)

      window.open(text.href,'_blank')
    },
    productAll() {
      let { platformTagIndex } = this;
      if (platformTagIndex == 1) {

       // this.$router.push('/solution')
        this.toOther('/solution')
      } else if (platformTagIndex == 2) {

        // this.$router.push('/replace?item=3')
        this.toOther('/replace?item=3')
      } else if(platformTagIndex == 3){

        // this.$router.push('/replace?item=2')

        this.toOther('/replace?item=2')
      }else if(platformTagIndex == 4){

        // this.$router.push('/replace?item=1')

        this.toOther('/replace?item=1')
      }
    },

    toOther(url){
      let text=this.$router.resolve(url)

      window.open(text.href,'_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
.home-platform {
  padding: 64px 0;
  background: url(../../assets/img/yptbg.png) no-repeat;
  background-size: 100% 100%;

  > strong {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: block;
    line-height: 45px;
    margin-bottom: 16px;
  }

  > p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 28px;
  }

  > div {
    width: 1264px;
    margin: 48px auto 0;
    display: flex;
    justify-content: space-between;

    > img {
      width: 500px;
    }

    > div {
      width: 728px;
      height: 666px;
      background: #ebeef5;

      .home-platform-tag {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        > span {
          height: 60px;
          border-bottom: 2px solid transparent;
          line-height: 60px;
          margin-right: 40px;
          opacity: 0.65;
          font-size: 16px;
          color: #000000;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: #ea0b06;
            border-color: #ea0b06;
          }
        }
      }

      .home-platform-list {
        padding: 0 24px 24px;
        display: flex;
        flex-wrap: wrap;
        height: 544px;

        > div {
          padding: 16px 24px;
          display: flex;
          align-items: center;
          width: 210px;
          margin-right: 24px;
          margin-top: 24px;
          background: #fff;
          cursor: pointer;
          transition: all 0.3s;

          &:nth-child(3n) {
            margin-right: 0;
          }

          > img {
            width: 48px;
            height: 48px;
            margin-right: 8px;
          }

          > span {
            flex: 1;
            line-height: 24px;
            max-height: 48px;
            overflow: hidden;
          }

          &:hover {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

            > span {
              color: #ea0b06;
            }
          }
        }
      }

      > p {
        padding: 0 24px;
        text-align: right;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);

        > span {
          cursor: pointer;

          &:hover {
            color: #ea0b06;
          }
        }
      }
    }
  }
}
</style>
