<template>
  <div class="home-serve">
    <strong>自主创新数字化转型赋能全服务</strong>
    <p>自主创新数字化转型全面服务能力</p>
    <div class="home-serve-park">
      <p>政府侧-对接园区</p>
      <div>
        <div class="home-serve-park-news">
          <div class="home-serve-park-news-title">
            <p>
              <span
                :class="{ active: newsTagIndex == 1 }"
                @click="newsTagIndex = 1"
                >鸿联资讯</span
              >
              <span
                :class="{ active: newsTagIndex == 2 }"
                @click="newsTagIndex = 2"
                >产业政策</span
              >
            </p>
            <span @click="newInfo('/policy')"
              >查看更多 ></span
            >
          </div>
          <div class="home-serve-park-news-list">
            <template v-if="newsTagIndex == 1">
              <div
                v-for="(item, index) in newsData"
                :key="index"
                @click="newInfo(item.url)"
              >
                <div class="home-serve-park-news-list-date">
                  <p>{{ item.date.substr(8, 2) }}</p>
                  <span>/</span>
                  <div>
                    <span>{{ item.date.substr(5, 2) }}月</span>
                    <span>{{ item.date.substr(0, 4) }}</span>
                  </div>
                </div>
                <div class="home-serve-park-news-list-info">
                  <p>
                    <img src="@/assets/img/new.png" alt="new" v-if="index == 0"/>{{ item.title }}
                  </p>
                  <span>{{ item.desc }}</span>
                </div>
              </div>
            </template>
            <template v-if="newsTagIndex == 2">
              <div
                v-for="(item, index) in policyData"
                :key="index"
                @click="newInfo(item.url)"
              >
                <div class="home-serve-park-news-list-date">
                  <p>{{ item.date.substr(8, 2) }}</p>
                  <span>/</span>
                  <div>
                    <span>{{ item.date.substr(5, 2) }}月</span>
                    <span>{{ item.date.substr(0, 4) }}</span>
                  </div>
                </div>
                <div class="home-serve-park-news-list-info">
                  <p>
                    <img src="@/assets/img/new.png" alt="new" v-if="index == 0"/>{{ item.title }}
                  </p>
                  <span>{{ item.desc }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="home-serve-park-map-box">
          <strong>重点园区介绍</strong>
          <div>
            <div class="home-serve-park-map" @mouseenter="mapMouseenter" @mouseleave="mapMouseleave">
              <div class="home-serve-park-map-num">
                <p>
                  <img src="@/assets/img/icon1.png" alt="new" />数字化转型赋能园区<span>19</span>
                </p>
                <p>
                  <img src="@/assets/img/icon2.png" alt="new" />鸿蒙化升级赋能园区<span>4</span>
                </p>
              </div>
              <div class="home-serve-park-map-mark">
                <province-map :province="selectType"/>
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="广东"
                  type="guangdong"
                  :data="guangdong"
                  style="top: 370px; right: 190px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="江苏"
                  type="jiangsu"
                  :data="jiangsu"
                  style="top: 228px; right: 124px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="山东"
                  type="shandong"
                  :data="shandong"
                  style="top: 176px; right: 160px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="重庆"
                  type="chongqing"
                  :data="chongqing"
                  style="top: 258px; right: 284px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="安徽"
                  type="anHui"
                  :data="anHui"
                  style="top: 234px; right: 160px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="福建"
                  type="fujian"
                  :data="fujian"
                  style="top: 320px; right: 128px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="甘肃"
                  type="gansu"
                  :data="gansu"
                  style="top: 182px; right: 340px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="广西"
                  type="guangxi"
                  :data="guangxi"
                  style="top: 350px; right: 250px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="河南"
                  type="henan"
                  :data="henan"
                  style="top: 200px; right: 210px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="湖北"
                  type="hubei"
                  :data="hubei"
                  style="top: 250px; right: 210px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="辽宁"
                  type="liaoning"
                  :data="liaoning"
                  style="top: 116px; right: 110px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="陕西"
                  type="shanxi"
                  :data="shanxi"
                  style="top: 192px; right: 270px"
                />
                <city-park
                  :select="selectType"
                  @selectChange="selectChangeMap"
                  province="浙江"
                  type="zhejiang"
                  :data="zhejiang"
                  style="top: 264px; right: 104px"
                />
              </div>
            </div>
            <div class="home-serve-park-map-info" @mouseenter="mapMouseenter" @mouseleave="mapMouseleave">
              <a-carousel>
                <div v-for="(item, index) in mapData" :key="index" class="home-serve-park-map-info-list" >
                  <img :src="item.img" />
                  <div>
                    <p>{{ item.title }}</p>
                    <span>{{ item.desc }}</span>
                  </div>
                </div>
              </a-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <serve-enterprise />
    <div class="home-serve-num">
      <div>
        <div>
          <p>150+</p>
          <span>牵引区域政策</span>
        </div>
        <div>
          <p>200+</p>
          <span>对接产业园区</span>
        </div>
        <div>
          <p>80+</p>
          <span>赋能区域园区</span>
        </div>
        <div>
          <p>42万+</p>
          <span>对接全国企业</span>
        </div>
        <div>
          <p>12万+</p>
          <span>赋能区域企业</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityPark from "./components/cityPark.vue";
import serveEnterprise from "./components/serveEnterprise.vue";
import provinceMap from "./components/provinceMap.vue";
export default {
  components: {
    cityPark,
    serveEnterprise,
    provinceMap
  },
  data() {
    return {
      newsTagIndex: 1,
      newsData: [
        {
          title:
            "《中山市制造业数字化智能化转型银行贷款贴息与风险补偿实施细则》解读",
          url: "/policy/info?id=1531528465113763842",
          desc:'为贯彻落实《中山市推进制造业数字化智能化转型发展若干政策措施》，加大制造业数字化智能化转型金融财政支持力度，规范专项资金管理，提高资金使用效率，按照《中山市工业发展专项资金管理暂行办法》，起草了《中山市制造业数字化智能化转型银行贷款贴息与风险补偿实施细则》（以下简称《实施细则》）。',
          date: "2022-05-12",
        },
        {
          title: "天津市促进首台（套）重大技术装备示范应用若干措施",
          url: "/policy/info?id=1531195897776459778",
          desc:'严格执行招标投标法规政策。招标单位不得对参与投标的首台套产品提出市场占有率、使用业绩等要求，不得超出招标项目实际需要或套用特定产品设置评价标准、技术参数等，消除招标环节的歧视性指标。相关主管部门按照职责分工对首台套产品的招标投标活动实施监督。',
          date: "2022-03-16",
        },
        {
          title: "天津市促进内外贸一体化发展若干措施的通知",
          url: "/policy/info?id=1531195204730638338",
          desc:'为深入贯彻党中央、国务院决策部署，加快培育建设国际消费中心城市和区域商贸中心城市，充分发挥市场优势和内需潜力，提高统筹利用两个市场、两种资源的能力，促进内外贸一体化发展，打造国内大循环的重要节点、国内国际双循环的战略支点。',
          date: "2022-05-20",
        },
      ],
      policyData: [
        {
          title:
            "中山市工业和信息化局关于印发中山市制造业数字化智能化转型银行贷款贴息与风险补偿实施细则的通知",
          url: "/policy/info?id=1531526321455321090",
          date: "2022-05-12",
          desc:'本实施细则有效期内，对制造业企业使用金融机构“数字贷”等金融产品进行数字化智能化转型发展给予全额贴息，单个企业每年获得贴息金额最高不超过3000万元，政策有效期内贴息期限最长不超过3年。'
        },
        {
          title:
            "关于印发中山市新一代信息技术产业发展项目资助实施细则补充规定的通知",
          url: "/policy/info?id=1531516799441457153",
          date: "2021-07-13",
          desc:'关于“方向一、支持新一代信息技术产业项目落地”的补充规定。 1.固定资产投资补贴。对经市政府批准引进的投资总额2000万元及以上，且已完成投资承诺对应阶段任务和已完成项目固定资产投资总额50%的新一代信息技术产业项目,按照最高不超过实际完成固定资产投资总额的10%给予奖补。'
        },
        {
          title:
            "中山市工业和信息化局关于印发中山市制造业企业数字化智能化标杆示范认定与奖励实施细则的通知",
          url: "/policy/info?id=1531513226855010306",
          date: "2022-04-15",
          desc:'鼓励数字化智能化示范工厂建设。支持制造业企业开展内外部全业务流程数字化智能化转型，实施企业资源计划、产品生命周期、生产执行、供应链管理、客户关系等工业软件全覆盖，对工作流、信息流、物流和资金流进行有效管理。'
        },
      ],
      mapData:[
        {
          title: "南京未来科技城",
          desc: "南京未来科技城位于高铁南站至禄口机场发展“金轴”与绕越高速交汇点，规划面积38.1平方公里，核心区面积4.62平方公里，科教人才、区位交通、生态环境优势突出",
          url: "https://nj.jfh.com/future/nanjing/introduction/index.html",
          img: require("@/assets/img/js1.png"),
        },
        {
          title: "苏州高新区",
          desc: "开发建设以来，苏州高新区从无到有、从小到大，不仅成为苏州经济的重要增长极、自主创新的示范区和全市高新技术产业基地，而且成为苏州现代化都市的有机组成部分和最繁华的金融商贸区之一。",
          url: "https://szgx.jfh.com/enterPark.html",
          img: require("@/assets/img/js2.png"),
        },
        {
          title: "宜城软件园",
          desc: "宜城软件园以“平台+基地”的模式构建“园区大脑”，设立“一个平台一中心四大服务体系”，规划建设产品展示厅、体验馆等功能区域，着力搭建软硬件一体化综合管理智慧园区。",
          url: "",
          img: require("@/assets/img/js3.jpeg"),
        },
        {
          title: "中软国际Harmony智联科技创新园",
          desc: "2021年9月，盐城市人民政府与中软国际签署战略合作框架协议，双方合作共建鸿蒙科技创新园，并在盐城落地解放号鸿联联创中心，以场景构建带动技术应用，以数智服务汇聚产业生态，打造“两平台”+“三中心”+“N个示范场景”",
          url: "",
          img: require("@/assets/img/js4.jpeg"),
        },
        {
          title: "中软国际（苏州）鸿联车联网联创中心",
          desc: "“中软国际解放号(苏州)鸿联车联网联创中心”将打造鸿联车联网产业使能、释能、赋能平台，为长三角区域车联网产业的智能化发展构建整体的资源要素、解决方案、系统平台等数字基础设施和使能中心。",
          url: "",
          img: require("@/assets/img/js5.jpeg"),
        },
        ],
      anHui: [
        {
          title: "合肥高新技术产业开发区",
          desc: "合肥国家高新技术产业开发区（简称合肥高新区）是1991年国务院首批设立的国家级高新区，面积128平方公里，常住人口20余万。合肥高新区是安徽省新兴产业门类最全、创新潜力与活力最优、金融资本最为活跃、政策集成度最高、人才资源最为丰富的地区之一，获得国家首批双创示范基地、国家自主创新示范区、国家生态工业示范园区、国家创新型科技园区、国家知识产权示范园区、全国首家综合性安全产业示范园区、全国模范劳动关系和谐工业园区等多项国家级荣誉，2016年，在全国国家高新区综合排名中位居第7位。",
          url: "https://hf.jfh.com/hefei/introduction/",
          img: require("@/assets/img/ah1.png"),
        },
      ],
      fujian: [
        {
          title: "福建武夷高新技术园区",
          desc: "武夷智谷·软件园选址南平市武夷新区省道S303以东、新岭河以南，由福建武夷高新技术园区开发建设有限公司承建和运营招商管理，一期规划3.6平方公里(360公倾)，可建设面积150公倾,总建筑面积192万平方米，计划总投资65亿(含土地征迁和开发)，可容纳5万人。",
          url: "https://np.jfh.com/nanping/introduction/",
          img: require("@/assets/img/fj1.png"),
        },
        {
          title: "厦门软件园",
          desc: "厦门云上软件园地处厦门软件园三期，位于厦门集美新城核心区，毗邻大学城，背靠河南山，面向杏林湾，依山傍海，风景秀丽，是集美新城的最重要组成部分。厦门软件园三期总规划面积10平方公里,总投资460亿。",
          url: "https://xm.jfh.com/xiamen/introduction/",
          img: require("@/assets/img/fj2.png"),
        },
      ],
      gansu: [
        {
          title: "兰州科技创新园",
          desc: '近年来，为坚持以开放促发展，加快新兴产业培育，兰州市城关区以国家"一带一路"建设、"大众创业万众创新"、华夏文明传承创新区建设、兰西城市群发展规划、兰白科技创新改革试验区建设等为行动抓手，充分发挥"一带一路"黄金节点区位优势，在兰西城市群建设中发挥示范带动作用，在兰白科技创新改革试验区建设中发挥科技引领作用，',
          url: "https://lz.jfh.com/lanzhou/introduction/index.html",
          img: require("@/assets/img/gs1.png"),
        },
      ],
      guangdong: [
        {
          title: "广州民营科技园",
          desc: "广州民营科技园（下称民科园）位于白云区中北部，是白云区委、区政府管理的唯一一个国家级高新区，被纳入珠三角国家自主创新示范区、广州市国际科技创新枢纽体系，并被确定为首批国家小型微型企业创新创业示范基地。",
          url: "https://by.jfh.com/baiyun/introduction/",
          img: require("@/assets/img/gz1.png"),
        },
      ],
      guangxi: [
        {
          title: "桂林经济开发区",
          desc: "桂林经开区位于美丽的漓江之西，属柳江水系，是桂林新区的重要组成部分，建设经开区是市委、市政府为了更好的保护漓江，贯彻落实“绿水青山就是金山银山”的科学理论，重振桂林工业雄风，加快国际旅游胜地建设步伐而做出的一个重大战略决策。",
          url: "https://gl.jfh.com/guilin/introduction/",
          img: require("@/assets/img/gx1.png"),
        },
        {
          title: "广西柳州柳东新区",
          desc: "柳东新区位于柳州市东北部，成立于2007年，统辖柳州高新区，整建制托管雒容镇和洛埠镇，辖区总面积约381.5平方公里，规划面积203平方公里，建设面积138平方公里，是广西壮族自治区党委、政府重点发展的三大城市新区之一，",
          url: "https://liuzhou.jfh.com/liuzhou/introduction/",
          img: require("@/assets/img/gx1.png"),
        },
      ],
      henan: [
        {
          title: "中软国际郑州鸿联联创营",
          desc: "郑州鸿联联创营围绕“赋能企业成长，联创解决方案；繁荣鸿蒙生态，加速产业集聚”，对上连接政府侧、集团总部，推动场景释放；对下赋能物联网、智能家居等产业，联创解决方案。",
          url: "",
          img: require("@/assets/img/hn1.png"),
        },
      ],
      hubei: [
        {
          title: "武汉东湖新技术开发区",
          desc: "武汉东湖新技术开发区于1988年创建成立，1991年被国务院批准为首批国家级高新区，2001年被原国家计委、科技部批准为国家光电子产业基地，即“武汉·中国光谷”，2007年被国家发改委批准为国家生物产业基地，2009年被国务院批准为全国第二个国家自主创新示范区",
          url: "https://wh.jfh.com/wuhan/introduction/",
          img: require("@/assets/img/hb1.png"),
        },
      ],
      jiangsu: [
        {
          title: "南京未来科技城",
          desc: "南京未来科技城位于高铁南站至禄口机场发展“金轴”与绕越高速交汇点，规划面积38.1平方公里，核心区面积4.62平方公里，科教人才、区位交通、生态环境优势突出",
          url: "https://nj.jfh.com/future/nanjing/introduction/index.html",
          img: require("@/assets/img/js1.png"),
        },
        {
          title: "苏州高新区",
          desc: "开发建设以来，苏州高新区从无到有、从小到大，不仅成为苏州经济的重要增长极、自主创新的示范区和全市高新技术产业基地，而且成为苏州现代化都市的有机组成部分和最繁华的金融商贸区之一。",
          url: "https://szgx.jfh.com/enterPark.html",
          img: require("@/assets/img/js2.png"),
        },
        {
          title: "宜城软件园",
          desc: "宜城软件园以“平台+基地”的模式构建“园区大脑”，设立“一个平台一中心四大服务体系”，规划建设产品展示厅、体验馆等功能区域，着力搭建软硬件一体化综合管理智慧园区。",
          url: "",
          img: require("@/assets/img/js3.jpeg"),
        },
        {
          title: "中软国际Harmony智联科技创新园",
          desc: "2021年9月，盐城市人民政府与中软国际签署战略合作框架协议，双方合作共建鸿蒙科技创新园，并在盐城落地解放号鸿联联创中心，以场景构建带动技术应用，以数智服务汇聚产业生态，打造“两平台”+“三中心”+“N个示范场景”",
          url: "",
          img: require("@/assets/img/js4.jpeg"),
        },
        {
          title: "中软国际（苏州）鸿联车联网联创中心",
          desc: "“中软国际解放号(苏州)鸿联车联网联创中心”将打造鸿联车联网产业使能、释能、赋能平台，为长三角区域车联网产业的智能化发展构建整体的资源要素、解决方案、系统平台等数字基础设施和使能中心。",
          url: "",
          img: require("@/assets/img/js5.jpeg"),
        },
      ],
      liaoning: [
        {
          title: "中软国际解放号（东北）数字经济协同创新基地",
          desc: "2020年11月，中软国际与辽宁省人民政府为推动辽宁省产业数字化和数字产业化建设签署战略合作协议，在沈抚投资建设“中软国际解放号（东北）数字经济协同创新基地”项目，并于2021年1月注册成立辽宁解放号网络科技有限公司，负责基地的整体建设和业务运营，以沈抚示范区为创新示范",
          url: "",
          img: require("@/assets/img/ln1.jpeg"),
        },
      ],
      shandong: [
        {
          title: "解放号东北亚服务平台",
          desc: "中软国际解放号青岛云上软件园是中软国际在国内落户的首个云上软件园，坐落于高新区中科研发城，面积约1800平米，是高新区通过傍大引强战略链接华为系生态伙伴吸引的首个行业50强项目。",
          url: "",
          img: require("@/assets/img/sd1.png"),
        },
        {
          title: "济宁国家高新技术产业开发区",
          desc: "济宁国家高新区创建于1992年5月，辖5个街道，人口25万，面积255平方公里，是国家科技创新服务体系、创新型产业集群、战略性新兴产业知识产权集群管理、科技创业孵化链条试点高新区及省级人才管理改革试验区、山东省科技金融试点高新区。",
          url: "https://jn.jfh.com/jining/introduction/",
          img: require("@/assets/img/sd2.png"),
        },
        {
          title: "威海经济技术开发区",
          desc: "“中软国际（威海）云上软件园，位于威海市经济技术开发区香港路18号智慧大厦，园区交通便捷，大厦内部食堂、健身房配套完善，周边商场、餐饮、娱乐、医院等配套齐全，是经济技术开发区的核心位置。中软国际（威海）云上软件园以威海服务贸易产业园子园区的形式",
          url: "https://weihai.jfh.com/weihai/introduction/",
          img: require("@/assets/img/sd3.png"),
        },
      ],
      shanxi: [
        {
          title: "西安高新技术产业开发区",
          desc: "西安高新技术产业开发区是1991年经国务院首批批准设立的国家级高新区，是国家确定的六个建设世界一流科技园区之一，是国家自主创新示范区，全面创新改革试验先行区和中国（陕西）自由贸易试验区核心片区，2016年，实现营业总收入1.36万亿元，外贸进出口总额超过1500亿元，在全国146家国家级高新区综合排名第四。",
          url: "https://xa.jfh.com/xian/introduction/",
          img: require("@/assets/img/sx1.png"),
        },
      ],
      zhejiang: [
        {
          title: "宁波国家高新区",
          desc: " 宁波国家高新区始建于1999年,是宁波市实施科教兴市“一号工程”和建设创新型城市的核心载体。2007年,经国务院批准升级为国家级高新区,成为全国第一家由省级高新区成功升级的国家高新区。",
          url: "https://nb.jfh.com/ningbo/introduction/",
          img: require("@/assets/img/zj1.png"),
        },
        {
          title: "嘉兴经济技术开发区",
          desc: " 2013年，为充分发挥国家级开发区在经济社会发展中的辐射、带动作用，根据浙江省委、省政府提出的高标准推进开发区整合提升工作的精神和要求，嘉兴市委、市政府决定以国家级开发区——嘉兴经济技术开发区（国际商务区）为核心区块对嘉兴工业园区（含嘉兴科技城）、嘉兴秀洲经济开发区、嘉兴港区（含嘉兴出口加工区）三个省级开发区（园区）进行整合提升，总面积达284.8平方公里",
          url: "http://jiaxing.jfh.com/jiaxing/introduction/",
          img: require("@/assets/img/zj2.png"),
        },
        {
          title: "中软国际（乌镇）鸿联联创中心",
          desc: "中软国际（乌镇）鸿联联创中心依托HarmonyOS的软件硬件生态，整体围绕构建一个鸿联云平台、一个鸿联云创新场景应用平台和四个中心（全球智慧场景展示中心、技术培训中心、协同创新中心、测试认证中心），打造使能、释能、赋能平台。",
          url: "",
          img: require("@/assets/img/zj3.png"),
        },
      ],
      chongqing: [
        {
          title: "重庆仙桃数据谷",
          desc: "2014年3月17日，重庆市政府第40次常务会研究，决定规划建设仙桃大数据产业园，重点布局大数据、小传感、海存储、云应用四大产业板块，发展金融大数据、健康大数据、教育大数据、跨境电商等高附加值产业。仙桃国际数据谷（简称仙桃谷）是适应全球大数据和智能硬件产业快速崛起趋势",
          url: "https://cq.jfh.com/chongqing/introduction/",
          img: require("@/assets/img/cq1.png"),
        },
      ],
      selectType: "jiangsu",
      provinceList:[{
        name:'jiangsu',
      },{
        name:'anHui',
      },{
        name:'zhejiang',
      },{
        name:'fujian',
      },{
        name:'guangdong',
      },{
        name:'guangxi',
      },{
        name:'chongqing',
      },{
        name:'gansu',
      },{
        name:'shanxi',
      },{
        name:'henan',
      },{
        name:'hubei',
      },{
        name:'shandong',
      },{
        name:'liaoning',
      }],
      provinceIndex:0,
      timer:null
    };
  },
  mounted(){
    let {provinceList,provinceIndex} = this
    this.timer = setInterval(() => {
      provinceIndex = provinceIndex + 1
      if(provinceIndex == provinceList.length){
        provinceIndex = 0
      }
      this.provinceIndex = provinceIndex
      this.selectType = provinceList[provinceIndex].name
    }, 5000);
  },
  methods: {
    selectChangeMap(val) {
      let {provinceList} = this
      this.selectType = val.type;
      this.mapData = val.data
      for (let index = 0; index < provinceList.length; index++) {
        if(provinceList[index].name == val.type){
          this.provinceIndex = index
        }
      }
    },
    newInfo(url) {

    let text=this.$router.resolve({
        path:url
      })

      window.open(text.href,'_blank')
    },
    mapMouseenter(){
      clearInterval(this.timer)
    },
    mapMouseleave(){
      let {provinceList,provinceIndex} = this
      this.timer = setInterval(() => {
        provinceIndex = provinceIndex + 1
        if(provinceIndex == provinceList.length){
          provinceIndex = 0
        }
        this.provinceIndex = provinceIndex
        this.selectType = provinceList[provinceIndex].name
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.home-serve {
  padding-top: 64px;
  background: url(../../assets/img/fwbj.png) top center no-repeat;
  background-size: 100% 100%;
  > strong {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: block;
    line-height: 45px;
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 28px;
  }
  .home-serve-park {
    width: 1264px;
    margin: 48px auto 0;
    > p {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 32px;
    }
    > div {
      .home-serve-park-news {
        margin-bottom: 50px;
        .home-serve-park-news-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 28px;
          > p {
            display: flex;
            > span {
              font-size: 20px;
              color: rgba(0, 0, 0, 0.85);
              font-weight: 600;
              line-height: 20px;
              cursor: pointer;
              &:last-child {
                margin-left: 16px;
                padding-left: 16px;
                border-left: 1px solid rgba(0, 0, 0, 0.45);
              }
              &.active {
                color: #ea0b06;
              }
            }
          }
          > span {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            &:hover{
              color: #ea0b06;
            }
          }
        }
        .home-serve-park-news-list {
          > div {
            display: flex;
            align-items: center;
            padding: 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.09);
            cursor: pointer;
            .home-serve-park-news-list-date{
              display: flex;
              align-items: center;
              margin-right: 21px;
              >p{
                font-size: 40px;
                color: rgba(0,0,0,0.65);
                text-align: center;
                line-height: 40px;
              }
              >span{
                margin: -6px 8px 0;
                font-size: 40px;
                line-height: 40px;
                color: rgba(0,0,0,0.25);
              }
              >div{
                >span{
                  display: block;
                  font-size: 14px;
                  color: rgba(0,0,0,0.65);
                  line-height: 21px;
                }
              }
            }
            .home-serve-park-news-list-info{
              flex: 1;
              border-left: 1px solid rgba(0, 0, 0, 0.09);
              padding: 4px 0 4px 37px;
              >p{
                display: flex;
                align-items: center;
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
                margin-bottom: 10px;
                font-weight: bold;
                transition: all .3s;
                >img{
                  width: 44px;
                  margin-right: 8px;
                }
              }
              >span{
                display: block;
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
              }
              &:hover{
                >p{
                  color: #ea0b06;
                }
              }
            }
          }
        }
      }
      .home-serve-park-map-box{
        height: 580px;
        >strong{
          font-size: 20px;
          color: rgba(0,0,0,0.85);
          font-weight: 600;
          display: block;
          line-height: 28px;
          margin-bottom: 37px;
        }
        >div{
          display: flex;
          justify-content: space-between;
          .home-serve-park-map {
            width: 728px;
            position: relative;
            .home-serve-park-map-num{
              position: absolute;
              top: 20px;
              left: 50%;
              transform: translateX(-50%);
              > p {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                > img {
                  width: 32px;
                  height: 32px;
                  margin-right: 12px;
                }
                > span {
                  color: #ea0b06;
                }
              }
            }
            .home-serve-park-map-mark{
              position: relative;
              > img {
                display: block;
                max-width: 100%;
              }
              > div {
                position: absolute;
              }
            }
          }
          .home-serve-park-map-info{
            width: 504px;
            .home-serve-park-map-info-list{
              >img{
                display: block;
                width: 100%;
                height: 296px;
              }
              >div{
                >p{
                  margin: 24px 0 12px;
                  font-size: 16px;
                  color: rgba(0,0,0,0.85);
                  font-weight: bold;
                  line-height: 22px;
                }
                >span{
                  font-size: 14px;
                  color: rgba(0,0,0,0.65);
                  line-height: 22px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  .home-serve-num {
    background: rgb(245, 246, 250);
    user-select: none;
    > div {
      width: 1264px;
      margin: 0 auto;
      height: 204px;
      display: flex;
      align-items: center;
      > div {
        flex: 1;
        > p {
          font-size: 40px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          line-height: 28px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        > span {
          display: block;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          text-align: center;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
<style>
.home-serve-park-map-info .ant-carousel .slick-dots-bottom{
  bottom: -20px;
}
.home-serve-park-map-info .ant-carousel .slick-dots li button{
  background: #000;
}
.home-serve-park-map-info .ant-carousel .slick-dots li.slick-active button{
  background: #EA0B06;
}
</style>
