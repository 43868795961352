<template>
  <div class="home-box">
    <!-- <head-box/> -->
    <banner-box/>
    <div id="cloud" class="home-box-info">
      <platform/>
    </div>
    <div id="centre" class="home-box-info">
      <center/>
    </div>
    <div id="service" class="home-box-info">
      <serve/>
    </div>
    <div id="opportunity" class="home-box-info">
      <business/>
    </div>
    <div id="result" class="home-box-info">
      <result/>
    </div>
    <div id="about" class="home-box-info">
      <about/>
    </div>
  </div>
</template>

<script>
import headBox from "./head.vue";
import bannerBox from "./banner.vue";
import platform from "./platform.vue";
import center from "./center.vue";
import serve from "./serve.vue"
import business from "./business.vue"
import result from "./result.vue"
import about from "./about.vue"
export default {
  components: {
    headBox,
    bannerBox,
    platform,
    center,
    serve,
    business,
    result,
    about
  },
};
</script>

<style lang="scss" scoped>
.home-box{
  user-select: none;
}
</style>