<template>
  <div class="home-map-location" :class="{ active: type == select }">
    <span class="map-icon1" @click.stop="selectMap(type)"><i></i></span>
    <!-- <img src="@/assets/img/zb.png" alt="zuob" class="map-icon1" @click.stop="selectMap(type)"/> -->
    <img src="@/assets/img/zuobs.png" alt="zuobs" class="map-icon2" @click.stop="selectMap(type)" />
    <span>{{ province }}</span>
    <!-- <div @click.stop="map">
      <a-carousel>
        <div v-for="(item, index) in data" :key="index" class="home-map-location-list" >
          <img :src="item.img" />
          <div>
            <p>{{ item.title }}</p>
            <span>{{ item.desc }}</span>
          </div>
        </div>
      </a-carousel>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    type: {
      type: String,
    },
    select: {
      type: String,
    },
    province: {
      type: String,
    },
  },
  data() {
    return {
      mapType: "",
    };
  },
  watch:{
    select:function(val){
      if(val == this.type){
        // console.log(val)
        let obj = {
          type:val,
          data:this.data
        }
        this.$emit("selectChange", obj);
      }
    }
  },
  methods: {
    map() {},
    selectMap(type) {
      let obj = {
        type,
        data:this.data
      }
      this.$emit("selectChange", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-icon1::before,.map-icon1::after{position: absolute;width: 12px; height: 12px; border-radius: 50%;content: ''; }
.map-icon1::before{animation: scale 2s infinite; }
.map-icon1::after{animation: scale2 2s infinite; }
@keyframes scale{0%{ transform: scale(1); opacity:.6}100%{ transform: scale(2); opacity: 0;}}
@keyframes scale2{0%{ transform: scale(1);opacity:.6;}100%{ transform: scale(4);opacity:0;}}

@keyframes markactive{
  0% {transform: scale(0.7)}
  50% {transform: scale(1)}
  100% {transform: scale(0.7)}
}
.home-map-location {
  > img {
    width: 26px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  .map-icon1{
    position: relative;
    margin: 0 auto 8px;
    width: 12px;
    height: 12px;
    >i{
      border: 2px solid #fff;
      box-shadow: 0 0 4px 0 #ea0b06;
      background: #EA0B06;
      z-index: 10;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    &::after{
      display: block;
      background: #EA0B06;
      left: 50%;
      top: 50%;
      margin: -6px 0 0 -6px;
      z-index: 2;
    }
    &::before{
      display: block;
      background: #EA0B06;
      left: 50%;
      top: 50%;
      margin: -6px 0 0 -6px;
      z-index: 2;
    }
  }
  > span {
    display: block;
    text-align: center;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    line-height: 13px;
    text-align: center;
  }
  .map-icon2 {
    display: none;
  }
  > div {
    // display: none;
    position: absolute;
    width: 352px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
    padding: 16px;
    top: -100px;
    left: -370px;
    z-index: -10;
    opacity: 0;
    &::after {
      content: "";
      position: absolute;
      right: -14px;
      bottom: 34px;
      border-top: 7px solid transparent;
      border-left: 7px solid #fff;
      border-bottom: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  &.active {
    .map-icon1 {
      display: none;
    }
    .map-icon2 {
      display: block;
    }
    >span{
      color: #EA0B06;
      font-size: 16px;
      text-shadow: #fff 2px 0 0,#fff 0 2px 0,#fff -2px 0 0,#fff 0 -2px 0;
      margin-top: 6px;
    }
    > div {
      z-index: 10;
      opacity: 1;
      .home-map-location-list {
        display: flex !important;
        > img {
          width: 120px;
          height: 108px;
          margin-right: 16px;
        }
        > div {
          flex: 1;
          > p {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 12px;
          }
          > span {
            display: block;
            text-align: justify;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 21px;
            max-height: 63px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
<style>
.home-map-location .ant-carousel .slick-dots-bottom {
  bottom: -18px;
}
.home-map-location .ant-carousel .slick-dots li button {
  background: rgba(000, 000, 000, 0.2);
}
.home-map-location .ant-carousel .slick-dots li.slick-active button {
  background: #ea0b06;
}
</style>