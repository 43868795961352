<template>
  <div class="home-center">
    <strong>自主创新数字化转型赋能六中心</strong>
    <p>自主创新数字化转型鸿小二</p>
    <div>
      <div class="home-center-title">
        <strong>自主创新数字化转型赋能中心全景图</strong>
        <a-button class="btn-red" size="large" @click="toUrl"
          >国产自主创新技术升级</a-button
        >
      </div>
      <div class="home-center-center">
        <img src="@/assets/img/centerimg.png" alt="centerimg" />
        <div style="top: 0; left: -81px">
          <img src="@/assets/img/c1.png" alt="centerimg" />
          <div>
            <span>智联万物中心</span>
            <p>
              以市场需求为核心，通过产业数据、信息、资源的线上聚合和线下高效协同，汇聚并刻画中软国际生态及地方国产信创、鸿蒙（鸿联）生态的信息数据、成熟产品和示范案例等，由点及面的阐述企业发展中的痛点问题，从关键工序到全生产流程，帮助企业完成转型思维训练、场景案例搭建和转型体验。
            </p>
          </div>
        </div>
        <div style="top: 174px; left: -81px">
          <img src="@/assets/img/c2.png" alt="centerimg" />
          <div>
            <span>智联共创中心</span>
            <p>
              以联合创新为核心，汇聚自主创新的解决方案、产品、开发板、SOC和OpenHarmony等创新资源，联合区域内企业组建联合创新团队，提供低代码等工具，为区域内的行业应用和企业产品进行自主创新方案规划、指导和评估，拉通国产自主创新的器件供应链，实现国产自主创新产品的定型、推广和落地实施。
            </p>
          </div>
        </div>
        <div style="bottom: 0; left: -81px">
          <img src="@/assets/img/c3.png" alt="centerimg" />
          <div>
            <span>数智转型中心</span>
            <p>
              以提质增效、
              数字化转型、国产化替代和智转数改的精准施策、政策辅导和实施辅助为核心，提供区域产业数字化转型辅导与规划，提供客户需求牵引对接企业数字化转型，提供企业数字化转型&国产化替代的诊断、辅导、规划、牵引及落地，帮助企业完成政策辅导、数字化诊断和国产化咨询。
            </p>
          </div>
        </div>
        <div style="top: 0; right: -83px">
          <img src="@/assets/img/c4.png" alt="centerimg" />
          <div>
            <span>开源创新中心</span>
            <p>
              以开源创新为核心，借助开源社区、组织、机构和基金会等孵化推广手段为抓手转换业务机会，通过需求侧拉通企业中心能力，供给侧拉通社区和基金会生态，围绕行业共同发展技术能力，建立开源产业联盟，开展开源生态活动，汇聚开源资源，构建标准并打造标杆项目，完成最终的商业闭环。
            </p>
          </div>
        </div>
        <div style="top: 174px; right: -83px">
          <img src="@/assets/img/c5.png" alt="centerimg" />
          <div>
            <span>鸿联英才中心</span>
            <p>
              以产业发展和区域数字化转型需求为核心，以OpenHarmony、物联网、大数据和人工智能等先进技术为依托，结合区域的产业特性和企业特点，汇聚区域IoT行业硬件产品、系统软件和应用软件等各类资源，促进区域内外高校与区内企业持续对接，协助区域建设实训基地，帮助企业进行实战培训和校企联训。
            </p>
          </div>
        </div>
        <div style="bottom: 0; right: -83px">
          <img src="@/assets/img/c6.png" alt="centerimg" />
          <div>
            <span>未来实验中心</span>
            <p>
              以区域主导产业为核心，以OpenHarmony、物联网、大数据和人工智能等先进技术为依托，联合区域、行业协会和区域头部企业建设未来实验室，搭建创新产品实验环境、综合方案验证环境、各生态系统模拟环境，服务当地企业新产品/技术方案验证，跨企业综合方案验证和生态系统功能满足性验证。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toUrl() {
      window.open(`/account/userLayout/userScenes/add`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.home-center {
  padding: 64px 0;
  background: url(../../assets/img/centerbg.png) no-repeat;
  background-size: 100% 100%;
  > strong {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: block;
    line-height: 45px;
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 28px;
  }
  > div {
    width: 1264px;
    margin: 48px auto 0;
    .home-center-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      > strong {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      > button {
        padding: 0 36px;
      }
    }
    .home-center-center {
      position: relative;
      width: 1100px;
      margin: 0 auto;
      > img {
        display: block;
        margin: 0 auto;
      }
      > div {
        position: absolute;
        width: 302px;
        background: #fff;
        display: flex;
        cursor: pointer;
        // transition: all .3s;
        > img {
          height: 108px;
          transition: all 0.3s;
        }
        > div {
          padding: 16px;
          flex: 1;
          > span {
            display: block;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            margin-bottom: 12px;
            font-weight: bold;
          }
          > p {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 18px;
            height: 36px;
            overflow: hidden;
            // text-align: justify;
          }
        }
        &:hover {
          z-index: 2;
          display: block;
          padding: 20px 16px;
          top: 0 !important;
          bottom: 0;
          box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.07);
          > img {
            display: block;
            height: 160px;
            width: 100%;
            margin-bottom: 24px;
          }
          > div {
            width: 100%;
            padding: 0;
            > p {
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>
