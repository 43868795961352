<template>
  <div class="video-player-box" :style="{ width: width, height: height }">
    <video
      ref="videoPlayer"
      class="video-js videos"
      :poster="poster"
      :playsinline="true"
      :webkit-playsinline="true"
      :x5-playsinline="true"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    poster: {
      type: String,
    },
    width: {
      type: String,
      default: "1920px",
    },
    height: {
      type: String,
      default: "680px",
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>
.video-player-box {
  overflow: hidden;
  position: relative;
}
.vjs-control-bar {
  display: none !important;
}
.vjs-big-play-button {
  z-index: 10;
}
.videos {
  width: 1920px;
  height: 680px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
