<template>
  <div class="home-business">
    <strong>自主创新数字化转型赋能准商机</strong>
    <p>自主创新数字化转型预备或精准场景释放</p>
    <div>
      <div class="home-business-info">
        <div class="home-business-info-type">
          <p>专题场景</p>
          <div>
            <div>
              <span>5G+教育专题</span>
              <div>
                <p>“5G+教育”有哪些应用场景 5G影响教育的技术因素主要集中在：4K/8K高清视频交互分析，超高速、低延时的及分布式存储。将这些技术特征与教育进行匹配，“5G+教育”的应用场景就会清晰可见。</p>
              </div>
            </div>
            <div>
              <span>5G+科技专题</span>
              <div>
                <p>首批重点行业和应用场景涵盖电子设备生产、装备制造、钢铁、采矿、电力等5大行业，以及协同研发设计、远程设备操控、柔性生产制造等十大应用场景。“5G+工业互联网”已成为工业互联网和5G发展进程中产业热情最高、创新最活跃、成效最显著的领域之一。</p>
              </div>
            </div>
            <div>
              <span>5G+金融专题</span>
              <div>
                <p>5G正在渗透并重塑各大行业，而金融也是5G重点发力的领域之一。 2020年12月，中国信通院发布了《“5G+金融应用发展研究报告》。</p>
              </div>
            </div>
            <div>
              <span>5G+交通专题</span>
              <div>
                <p>5G+智慧交通的功能有：交通感知——非现场电子警察执法，交通动脉——信号控制系统，交通窗口——信息诱导发布系统，交通秩序——路边停车管理系统，交通小脑——全息路口感知系统。通俗地讲，智能交通的功能分为智能交管、智能疏通和智能驾驶三部分。</p>
              </div>
            </div>
            <div>
              <span>5G+医疗专题</span>
              <div>
                <p>5G+智慧医疗是通过高清视频实时互动与云AR、3D医疗建模等技术，大幅度提升医联体远程诊疗、精准扶贫能力和急救能力。医疗包括两部分：一是公共卫生服务范围，包括疾病预防、计划免疫、妇幼保健、急救、采血服务及职业病防治等领域。二是基本医疗。</p>
              </div>
            </div>
            <div>
              <span>5G+政务专题</span>
              <div>
                <p>5G+智慧政务是运用云计算、大数据、物联网等技术，通过监测、整合、分析、智能响应，实现各职能部门的各种资源的高度整合，提高政府的业务办理和管理效率。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="home-business-info-table">
          <div class="home-business-info-table-tag">
            <p>
              <span :class="{'active':businessTagIndex == 1}" @click="businessTagIndex = 1">场景清单</span>
              <span :class="{'active':businessTagIndex == 2}" @click="businessTagIndex = 2">最新揭榜</span>
            </p>
            <!-- <div>
              <a-button size="large" class="btn-red">我要释放场景</a-button>
              <a-button size="large" class="btn-color-bor-red">我要揭榜</a-button>
            </div> -->
          </div>
          <a-table v-show="businessTagIndex == 1" :columns="columns" :data-source="sceneTable" :pagination="false" :rowKey="(record,index)=>{return index}">
            <template slot="state" slot-scope="text">
              <p class="home-business-info-table-state">
                <span :class="{ jbz: text == '揭榜中' }"></span>{{ text }}
              </p>
            </template>
          </a-table>
          <a-table v-show="businessTagIndex == 2" :columns="newColumns" :data-source="newSceneTable" :pagination="false" :rowKey="(record,index)=>{return index}">
            <template slot="state" slot-scope="text">
              <p class="home-business-info-table-state">
                <span :class="{ jbz: text == '揭榜中' }"></span>{{ text }}
              </p>
            </template>
          </a-table>
        </div>
      </div>
      <div class="home-business-num">
        <div>
          <p>4300+</p>
          <span>应用场景</span>
        </div>
        <div>
          <p>1.4亿+</p>
          <span>企事业单位</span>
        </div>
        <div>
          <p>1700+</p>
          <span>场景活动</span>
        </div>
        <div>
          <p>800+</p>
          <span>场景成果</span>
        </div>
        <div>
          <p>100+</p>
          <span>场景领域</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "榜单名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "状态",
    dataIndex: "state",
    key: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "发榜时间",
    dataIndex: "sDate",
    key: "sDate",
  },
  {
    title: "截止时间",
    key: "eDate",
    dataIndex: "eDate",
  },
];
const sceneTable = [
  {
    name: "数字孪生平台及应用项目",
    state: "揭榜中",
    sDate: "2022/6/15",
    eDate: "1个月后",
  },
  {
    name: "施工现场智慧工地云平台",
    state: "揭榜中",
    sDate: "2022/6/10",
    eDate: "15天后",
  },
  {
    name: "智慧城市运行管理中心项目",
    state: "揭榜中",
    sDate: "2022/5/25",
    eDate: "2天后",
  },
  {
    name: "智慧园区管控中心",
    state: "揭榜中",
    sDate: "2022/4/25",
    eDate: "25天前",
  },
  {
    name: "基础设施智慧管理平台",
    state: "已挂帅",
    sDate: "2022/3/18",
    eDate: "2个月前",
  },
]

const newColumns = [
  {
    title: "榜单名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "揭榜单位",
    dataIndex: "enterprise",
    key: "enterprise",
  },
  {
    title: "发榜时间",
    dataIndex: "date",
    key: "date",
  },
];
const newSceneTable = [
  {
    name: "无人机光传输空地监测系统",
    enterprise: "南京东大智能化系统有限公司",
    date: "2022/6/15 12:23:00",
  },
  {
    name: "互联网大屏在线教育智慧平台",
    enterprise: "大汉软件股份有限公司",
    date: "2022/6/14 14:12:00",
  },
  {
    name: "智慧农路系统",
    enterprise: "江苏苏盟信息技术有限公司",
    date: "2022/6/8 09:15:09",
  },
  {
    name: "全域生态环境区块链智慧应用平台",
    enterprise: "国电南瑞科技股份有限公司",
    date: "2022/5/25 16:45:19",
  },
  {
    name: "智慧扬子“数字大脑”云平台",
    enterprise: "重庆立鼎科技有限公司",
    date: "2022/5/15 12:28:19",
  },
]
export default {
  data() {
    return {
      columns,
      sceneTable,
      newColumns,
      newSceneTable,
      businessTagIndex:1
    };
  },
};
</script>

<style lang="scss" scoped>
.home-business {
  padding-top: 64px;
  background: url(../../assets/img/sjbg.png) no-repeat;
  background-size: 100% 100%;
  > strong {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    display: block;
    line-height: 45px;
    margin-bottom: 16px;
  }
  > p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 28px;
  }
  > div {
    width: 1264px;
    margin: 48px auto 0;
    .home-business-info {
      display: flex;
      justify-content: space-between;
      .home-business-info-type {
        width: 512px;
        > p {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 600;
          line-height: 28px;
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          > div {
            margin-top: 24px;
            margin-right: 24px;
            height: 120px;
            width: 244px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            padding: 24px 16px;
            cursor: pointer;
            position: relative;
            >div{
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              height: 0;
              z-index: 2;
              transition: all .3s;
              overflow: hidden;
              >p{
                padding: 16px;
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                line-height: 24px;
                font-weight: 400;
              }
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(1){
              background: url(../../assets/img/5Gjy.png) no-repeat;
              background-size: 100% 100%;
              >div{
                background: url(../../assets/img/5Gjyd.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(2){
              background: url(../../assets/img/5Gkj.png) no-repeat;
              background-size: 100% 100%;
              >div{
                background: url(../../assets/img/5Gkjd.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(3){
              background: url(../../assets/img/5Gjr.png) no-repeat;
              background-size: 100% 100%;
              >div{
                background: url(../../assets/img/5Gjrd.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(4){
              background: url(../../assets/img/5Gjt.png) no-repeat;
              background-size: 100% 100%;
              >div{
                background: url(../../assets/img/5Gjtd.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(5){
              background: url(../../assets/img/5Gyl.png) no-repeat;
              background-size: 100% 100%;
              >div{
                top: auto;
                bottom: 0;
                background: url(../../assets/img/5Gyld.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:nth-child(6){
              background: url(../../assets/img/5Gzw.png) no-repeat;
              background-size: 100% 100%;
              >div{
                top: auto;
                bottom: 0;
                background: url(../../assets/img/5Gzwd.png) no-repeat;
                background-size: 100% 100%;
              }
            }
            &:hover{
              >span{
                display: none;
              }
              >div{
                height: 264px;
              }
            }
          }
        }
      }
      .home-business-info-table {
        width: 728px;
        .home-business-info-table-tag {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          > p {
            display: flex;
            > span {
              font-size: 28px;
              color: rgba(0, 0, 0, 0.85);
              font-weight: 600;
              line-height: 28px;
              cursor: pointer;
              &:last-child {
                margin-left: 16px;
                padding-left: 16px;
                border-left: 1px solid rgba(0, 0, 0, 0.45);
              }
              &.active {
                color: #ea0b06;
              }
            }
          }
          > div {
            > button {
              border-radius: 0 !important;
              &:last-child {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
    .home-business-num {
      height: 204px;
      display: flex;
      align-items: center;
      margin-top: 28px;
      border-top: 1px solid #eee;
      > div {
        flex: 1;
        > p {
          font-size: 40px;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          line-height: 28px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        > span {
          display: block;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          text-align: center;
          line-height: 28px;
        }
      }
    }
  }
}
.home-business-info-table-state {
  display: flex;
  align-items: center;
  > span {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: #ddd;
    margin-right: 8px;
    &.jbz {
      background: #52c41a;
    }
  }
}
</style>
<style>
.home-business-info-table .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  padding: 24px 16px;
}
.home-business-info-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
  background: #fafafa;
}
.home-business-info-table .ant-table-tbody>tr:last-child >td{
  border-bottom: 0;
}
</style>
