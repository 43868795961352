<template>
  <div>
    <video ref="videoPlayer" class="video-js" :poster="poster" :playsinline="true" :webkit-playsinline="true" :x5-playsinline="true" style="width: 1264px;height: 456px;"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    poster:{
      type:String
    }
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
        // this.play()
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>
.vjs-control-bar{
  display: none !important;
}
.vjs-big-play-button{
  z-index: 10;
}
</style>