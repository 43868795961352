<template>
  <div class="home-banner-box">
    <a-carousel autoplay>
      <div class="home-banner-list">
        <video-player
          :options="videoOptions"
          :poster="bannerImg"
          width="100%"
        />
        <div class="home-banner-info">
          <div>
            <strong>数字化转型赋能</strong>
            <span>自主创新数字化转型赋能实践者</span>
            <div>
              <a-button class="btn-red" size="large" @click="consultShow = true"
                >技术咨询</a-button
              >
              <a-button class="btn-color-red" size="large" @click="toUrl()"
                >下单升级</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-carousel>
    <consult-form :show="consultShow" v-model="consultShow" />
  </div>
</template>

<script>
import consultForm from "@/components/consultForm";
import videoPlayer from "@/components/VideoPlayer.vue";
export default {
  components: {
    consultForm,
    videoPlayer,
  },
  data() {
    return {
      consultShow: false,
      videoOptions: {
        autoplay: true,
        loop: true,
        muted: true,
        bigPlayButton: true,
        sources: [
          {
            src: "https://hlcloud-prod.obs.cn-north-4.myhuaweicloud.com/mainbanner.mp4",
            type: "video/mp4",
          },
        ],
      },
      bannerImg: require("@/assets/img/bannerbg.jpg"),
    };
  },
  methods: {
    toUrl() {
      window.open(`/account/userLayout/userScenes/add`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.home-banner-list {
  position: relative;
  display: block !important;
  > img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  .home-banner-info {
    position: absolute;
    width: 1264px;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    > div {
      > strong {
        display: block;
        font-size: 40px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 16px;
      }
      > span {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.65);
        display: block;
        line-height: 33px;
      }
      > div {
        margin-top: 40px;
        > button {
          padding: 0 36px;
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
<style>
.home-banner-box .ant-carousel .slick-dots li button {
  background: #000;
  opacity: 0.15;
}
.home-banner-box .ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #ea0b06;
}
</style>
