<template>
  <div class="province-img-box">
    <img class="province-img" src="@/assets/img/anHui.png" :class="{'active':province == 'anHui'}" alt="province_map">
    <img class="province-img" src="@/assets/img/chongqing.png" :class="{'active':province == 'chongqing'}" alt="province_map">
    <img class="province-img" src="@/assets/img/fujian.png" :class="{'active':province == 'fujian'}" alt="province_map">
    <img class="province-img" src="@/assets/img/gansu.png" :class="{'active':province == 'gansu'}" alt="province_map">
    <img class="province-img" src="@/assets/img/guangdong.png" :class="{'active':province == 'guangdong'}" alt="province_map">
    <img class="province-img" src="@/assets/img/guangxi.png" :class="{'active':province == 'guangxi'}" alt="province_map">
    <img class="province-img" src="@/assets/img/henan.png" :class="{'active':province == 'henan'}" alt="province_map">
    <img class="province-img" src="@/assets/img/hubei.png" :class="{'active':province == 'hubei'}" alt="province_map">
    <img class="province-img" src="@/assets/img/jiangsu.png" :class="{'active':province == 'jiangsu'}" alt="province_map">
    <img class="province-img" src="@/assets/img/liaoning.png" :class="{'active':province == 'liaoning'}" alt="province_map">
    <img class="province-img" src="@/assets/img/shandong.png" :class="{'active':province == 'shandong'}" alt="province_map">
    <img class="province-img" src="@/assets/img/shanxi.png" :class="{'active':province == 'shanxi'}" alt="province_map">
    <img class="province-img" src="@/assets/img/zhejiang.png" :class="{'active':province == 'zhejiang'}" alt="province_map">
  </div>
</template>

<script>
export default {
    props:['province']
}
</script>

<style>
.province-img-box{
    position: relative;
    height: 522px;
}
.province-img{
    position: absolute;
    opacity: 0;
}
.province-img.active{
    opacity: 1;
}
</style>